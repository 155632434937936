export const menu = {
    list: [
        { title: 'Направления', id: 'direction' },
        { title: 'Условия', id: 'conditions' },
        { title: 'Этапы', id: 'stages' },
        { title: 'FAQ', id: 'faq' },
    ],
};

export const hrGtag = {
    gtagHeader: {
        eventName: 'universalEvent',
        action: 'click',
        category: 'interactions',
        label: 'hr_stazher',
    },
    gtagFooter: {
        eventName: 'universalEvent',
        action: 'click',
        category: 'interactions',
        label: 'hr_stazher',
    },
    gtagForm: {
        eventName: 'universalEvent',
        action: 'form_send',
        category: 'conversions',
        label: 'hr_stazher',
    },
    gtagSlider: {
        eventName: 'universalEvent',
        action: 'click',
        category: 'interactions',
        label: 'hr_stazher',
    },
};
