import clsx from 'clsx';
import React, { useState } from 'react';

import { disclaimer } from '@/components/blocks/HRLanding/data/disclaimer';
import { socialHR as social } from '@/components/blocks/HRLanding/data/social';
import HRButton from '@/components/blocks/HRLanding/HRButton';
import HRMenu, { IHRMenu } from '@/components/blocks/HRLanding/Menu';
import CustomImage from '@/components/CustomImage';
import SubscriptionModal from '@/components/SubscriptionModal';
import { event, GTagEvent, universalEvent } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

interface IProps {
    handler?: () => void;
    menu: IHRMenu;
    hrGtag?: {
        [key: string]: GTagEvent;
    };
}

function HRFooter({ handler, menu, hrGtag }: IProps) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <div className={clsx(cn.footer)}>
            <div className={cn.wrap}>
                <div className={cn.grid}>
                    <div className={clsx(cn.left, cn.item)}>
                        <div className={cn.logo}>
                            <a href="/">
                                <CustomImage
                                    src="/img-next/svg/hr-landing/logo-footer.svg"
                                    alt="HR Landing"
                                    width="97"
                                    height="51"
                                />
                            </a>
                        </div>

                        <div className={clsx(cn.social)}>
                            <div className={cn.socialGrid}>
                                {social?.map(({ link, name, image, gtag }) => (
                                    <div className={cn.socialItem} key={link}>
                                        <a
                                            href={link}
                                            target="_blank"
                                            className={cn.socialLink}
                                            rel="noopener noreferrer"
                                            onClick={() => event(gtag)}
                                        >
                                            {image && <CustomImage src={image} alt={name} width={39} height={39} />}
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className={clsx(cn.right, cn.item)}>
                        <div className={clsx(cn.menu)}>
                            <HRMenu list={menu.list} />
                        </div>

                        <div className={cn.button}>
                            <HRButton
                                type="button"
                                label="Подать заявку"
                                onClick={() => {
                                    handler();
                                    universalEvent(hrGtag.gtagFooter);
                                }}
                            />
                        </div>
                    </div>
                    <div className={clsx(cn.bottom, cn.item)}>
                        <div role="presentation" className={clsx(cn.info)} onClick={() => setIsModalOpen(true)}>
                            Информация об изображениях, использованных на сайте.
                        </div>
                    </div>
                </div>
            </div>
            <SubscriptionModal
                isOpen={isModalOpen}
                isFail
                isInfoText
                handleClose={() => setIsModalOpen(false)}
                title={disclaimer}
            />
        </div>
    );
}

export default HRFooter;
