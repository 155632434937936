import clsx from 'clsx';
import React, { useRef } from 'react';

import { CrossIcon } from '@/components/blocks/HRLanding/CrossIcon';
import HRButton from '@/components/blocks/HRLanding/HRButton';
import HRMenu, { IHRMenu } from '@/components/blocks/HRLanding/Menu';
import useOnClickOutside from '@/hooks/useOnClickOutside';

import cn from './style.module.sass';

interface IProps {
    closeMenu: () => void;
    active?: boolean;
    handler?: () => void;
    menu: IHRMenu;
    btnLabel: string;
}

function HRMobileMenu({ closeMenu, active, handler, menu, btnLabel }: IProps) {
    const ref = useRef(null);

    useOnClickOutside(ref, () => closeMenu());

    return (
        <div className={clsx(cn.menu, active && cn.active, 'HRMobileMenu')} ref={ref}>
            <div className={cn.top}>
                <HRMenu closeMenu={closeMenu} list={menu.list} />
                <div className={cn.cross}>
                    <CrossIcon handleClick={closeMenu} />
                </div>
            </div>
            <div className={cn.bottom}>
                <HRButton type="button" label={btnLabel} isDark onClick={handler} />
            </div>
        </div>
    );
}

export default HRMobileMenu;
