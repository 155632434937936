import clsx from 'clsx';
import React from 'react';

import HRButton from '@/components/blocks/HRLanding/HRButton';
import { IHRRequirementsItem } from '@/components/blocks/HRLanding/types/hrRequirements';
import { universalEvent } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

function HRRequirementsContent({ list, name, isActive, handler, hrGtag }: IHRRequirementsItem) {
    return (
        <div className={clsx(cn.item, isActive && cn.active)}>
            {list?.map((item, index) => (
                <div className={clsx(cn.hrRequirementsContent)} key={index}>
                    <div className={clsx(cn.hrRequirementsContentTop)}>
                        <div
                            className={cn.hrRequirementsContentTitle}
                            dangerouslySetInnerHTML={{ __html: item.title }}
                        />

                        {item?.info.map((elem, idx) => (
                            <div className={cn.hrRequirementsItem} key={idx}>
                                <div
                                    className={cn.hrRequirementsTitle}
                                    dangerouslySetInnerHTML={{ __html: elem.title }}
                                />
                                <div className={cn.hrRequirementsSubtitle}>
                                    {elem.subtitle.map((innerElem, key) => (
                                        <div className={cn.hrRequirementsText} key={key}>
                                            <div className={cn.hrRequirementsCircle} />
                                            <div
                                                className={cn.hrRequirementsDescription}
                                                dangerouslySetInnerHTML={{
                                                    __html: innerElem,
                                                }}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={cn.hrRequirementsButton}>
                        <HRButton
                            type="button"
                            label="Подать заявку"
                            isDark
                            onClick={() => {
                                handler(name);
                                universalEvent(hrGtag.gtagSlider);
                            }}
                        />
                    </div>
                </div>
            ))}
        </div>
    );
}

export default HRRequirementsContent;
