import clsx from 'clsx';
import React from 'react';

import CustomImage from '@/components/CustomImage';

import cn from './style.module.sass';

interface IHRDetailConditionsList {
    hrDetailConditionsList: IHRDetailConditionsItem[];
}
interface IHRDetailConditions extends IHRDetailConditionsList {
    title: string;
    typeCard?: string;
}

export interface IHRDetailConditionsItem {
    icon: string;
    title: string;
    subtitle: string;
}

function HRDetailConditions({ hrDetailConditionsList, title, typeCard = 'big' }: IHRDetailConditions) {
    return (
        <div className={clsx(cn.detailConditions, 'hrIndent')} id="conditions">
            <div className={cn.wrap}>
                <div className={cn.detailConditionsBlock}>
                    <div className={cn.detailConditionsHeader}>{title}</div>
                    <div className={cn.detailConditionsList}>
                        {hrDetailConditionsList.map((item, index) => (
                            <div className={cn.detailConditionsInner} key={index}>
                                <div
                                    className={clsx(
                                        cn.detailConditionsItem,
                                        typeCard === 'small' && cn.detailConditionsItemSmall
                                    )}
                                >
                                    <CustomImage
                                        width={48}
                                        height={48}
                                        src={item.icon}
                                        className={cn.detailConditionsIcon}
                                        alt="detailConditionsIcon"
                                    />
                                    <div className={cn.detailConditionsTitle}>{item.title}</div>
                                    <div
                                        className={cn.detailConditionsSubtitle}
                                        dangerouslySetInnerHTML={{ __html: item.subtitle }}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HRDetailConditions;
