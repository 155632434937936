import React from 'react';

import cn from './style.module.sass';

interface IHRConditionsList {
    hrConditionsList: IHRConditionsItem[];
}

export interface IHRConditionsItem {
    title: string;
    subtitle: string;
}

function HRConditions({ hrConditionsList }: IHRConditionsList) {
    return (
        <div className={cn.conditions}>
            <div className={cn.wrap}>
                <div className={cn.conditionsBlock}>
                    {hrConditionsList.map((item, index) => (
                        <div className={cn.conditionsItem} key={index}>
                            <div className={cn.conditionsTitle}>
                                <span className={cn.conditionsTitleSlash}>/</span>
                                {item.title}
                            </div>
                            <div
                                className={cn.conditionsSubtitle}
                                dangerouslySetInnerHTML={{ __html: item.subtitle }}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default HRConditions;
