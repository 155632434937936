import 'swiper/components/navigation/navigation.min.css';

import React, { useRef, useState } from 'react';
// import required modules
import SwiperCore, { Navigation, Thumbs } from 'swiper';
import { SwiperSlide } from 'swiper/react';

import HRRequirementsCategory from '@/components/blocks/HRLanding/HRRequirements/HRRequirementsCategory';
import HRRequirementsContent from '@/components/blocks/HRLanding/HRRequirements/HRRequirementsContent';
import HRNextArrow from '@/components/blocks/HRLanding/HRRequirements/HRRequirementsSlider/nextArrow';
import HRPrevArrow from '@/components/blocks/HRLanding/HRRequirements/HRRequirementsSlider/prevArrow';
import { IHRRequirements } from '@/components/blocks/HRLanding/types/hrRequirements';
import CustomSwiper from '@/components/CustomSwiper';
import { ISwiperRef } from '@/types/animatedPromo/item';

import cn from './style.module.sass';

SwiperCore.use([Thumbs, Navigation]);

function HRRequirementsSlider({ hrRequirementsList, handler, hrGtag }: IHRRequirements) {
    const [active, setActive] = useState<number>(0);

    const swiperRefDots = useRef<ISwiperRef>(null);

    const settingsDots = {
        loop: true,
        autoplay: false,
        slidesPerView: 'auto',
        spaceBetween: 12,
        centeredSlides: true,
        slideToClickedSlide: true,
        watchOverflow: true,
        onActiveIndexChange: (swiper: { activeIndex: number; realIndex: number }) => {
            setActive(swiper.realIndex);
        },
    };

    return (
        <div className={cn.currentSlider}>
            <div className={cn.currentSliderTop}>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <CustomSwiper {...settingsDots} ref={swiperRefDots}>
                    {hrRequirementsList.map(({ id, name }, index) => (
                        <SwiperSlide key={index}>
                            <HRRequirementsCategory key={id} name={name} />
                        </SwiperSlide>
                    ))}
                </CustomSwiper>
                <HRPrevArrow listLength={hrRequirementsList?.length} swiperRef={swiperRefDots} />
                <HRNextArrow listLength={hrRequirementsList?.length} swiperRef={swiperRefDots} />
            </div>

            <div className={cn.currentSliderBottom}>
                {hrRequirementsList.map(({ list, id, name }, index) => (
                    <HRRequirementsContent
                        key={id}
                        list={list}
                        id={id}
                        name={name}
                        isActive={index === active}
                        handler={handler}
                        hrGtag={hrGtag}
                    />
                ))}
            </div>
        </div>
    );
}

export default HRRequirementsSlider;
