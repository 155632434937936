import clsx from 'clsx';
import React from 'react';

import cn from '@/components/blocks/HRLanding/HRRequirements/HRRequirementsSlider/style.module.sass';
import { IHRArrow } from '@/components/blocks/HRLanding/types/hrRequirements';
import { event } from '@/components/shared/utilities/analytics/metrics';

function HRNextArrow({ gtag, listLength, swiperRef }: IHRArrow) {
    if (listLength < 4) return null;

    const onClick = () => {
        swiperRef?.current?.swiper?.slideNext();
        if (gtag?.action) event(gtag);
    };

    return (
        <button
            type="button"
            aria-label="Right"
            onClick={onClick}
            className={clsx(cn.customArrow, cn.customArrowRight)}
        />
    );
}

export default HRNextArrow;
