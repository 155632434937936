export const hrInternshipList = [
    {
        icon: '/img-next/svg/hr-landing/hr-internship/snowflake.svg',
        title: 'Доверие, профессиональные наставники и&nbsp;поддержка',
    },
    {
        icon: '/img-next/svg/hr-landing/hr-internship/circle.svg',
        title: 'Прокачка твоих навыков и&nbsp;интересные реальные задачи',
    },
    {
        icon: '/img-next/svg/hr-landing/hr-internship/triangle.svg',
        title: 'Возможность продолжить карьеру в&nbsp;стабильном банке',
    },
];
