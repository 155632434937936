export const hrFaqList = [
    {
        title: 'Как я&nbsp;могу попасть на&nbsp;стажировку в&nbsp;Банк?',
        subtitle: 'Для этого необходимо заполнить заявку&nbsp;по <span class="openModal">ссылке</span>',
    },
    {
        title: 'Можно&nbsp;ли сочетать стажировку и&nbsp;учебу?',
        subtitle:
            'Да, конечно!<br/>\n' +
            'Главное&nbsp;&mdash; уделять стажировке 40&nbsp;часов в&nbsp;неделю летом и&nbsp;не&nbsp;менее 20&nbsp;часов в&nbsp;неделю с&nbsp;1&nbsp;сентября. Заранее оцени, сколько времени ты&nbsp;сможешь уделять стажировке и&nbsp;укажи в&nbsp;анкете.<br/>\n' +
            'Важно, чтобы ты&nbsp;указал максимальное количество часов, которое точно сможешь работать. Эта информация будет полезна для руководителей при рассмотрении твоей анкеты, ведь многие из&nbsp;них ищут стажеров, готовых работать наибольшее количество времени.',
    },
    {
        title: 'Я&nbsp;могу пройти стажировку дистанционно?',
        subtitle: 'Для прохождения стажировки требуется присутствие в&nbsp;офисе.',
    },
    {
        title: 'На&nbsp;каком курсе я&nbsp;могу попасть на&nbsp;стажировку?',
        subtitle:
            'Ждем студентов очных форм обучения, от&nbsp;3&nbsp;курса бакалавриата, готовых стажироваться полный рабочий день 40&nbsp;часов в&nbsp;неделю летом и&nbsp;не&nbsp;менее 20&nbsp;часов в&nbsp;неделю с&nbsp;1&nbsp;сентября',
    },
    {
        title: 'Сколько длится стажировка?',
        subtitle: 'Официальное оформление на&nbsp;срочный договор 3&nbsp;месяца + 4&nbsp;месяца как приятный бонус',
    },
    {
        title: 'Как оформляется стажировка?',
        subtitle: 'На период стажировки будет заключен срочный договор',
    },
    {
        title: 'Выдается ли ноутбук стажерам?',
        subtitle: 'Да, стажерам предоставляется необходимое оборудование',
    },
];
