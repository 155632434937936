import clsx from 'clsx';
import React, { useState } from 'react';

import { aboutOptions, hrLandingFormOptions, levelStudyOptions } from '@/components/blocks/HRLanding/data/form';
import { hrGtag, menu } from '@/components/blocks/HRLanding/data/header';
import { hrConditionsList } from '@/components/blocks/HRLanding/data/hrConditions';
import { hrDetailConditionsList } from '@/components/blocks/HRLanding/data/hrDetailConditions';
import { hrFaqList } from '@/components/blocks/HRLanding/data/hrFaq';
import { hrInternshipList } from '@/components/blocks/HRLanding/data/hrInternship';
import { hrRequirementsList } from '@/components/blocks/HRLanding/data/hrRequirements';
import { hrStageList } from '@/components/blocks/HRLanding/data/hrStage';
import { hrMain } from '@/components/blocks/HRLanding/data/main';
import HRConditions from '@/components/blocks/HRLanding/HRConditions';
import HRDemo from '@/components/blocks/HRLanding/HRDemo';
import HRDetailConditions from '@/components/blocks/HRLanding/HRDetailConditions';
import HRFaq from '@/components/blocks/HRLanding/HRFaq';
import HRFooter from '@/components/blocks/HRLanding/HRFooter';
import HRHeader from '@/components/blocks/HRLanding/HRHeader';
import HRInternship from '@/components/blocks/HRLanding/HRInternship';
import HRLandingForm from '@/components/blocks/HRLanding/HRLandingForm';
import HRMain from '@/components/blocks/HRLanding/HRMain';
import HRRequirements from '@/components/blocks/HRLanding/HRRequirements';
import HRStage from '@/components/blocks/HRLanding/HRStage';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import ModalWrapper from '@/components/modal/ModalWrapper';
import Feature from '@/domain/feature/Feature';

import cn from './style.module.sass';

export interface IHRLanding {
    _template?: 'hrLandingForm';
}

function HRLanding({ _template }: IHRLanding) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentTheme, setCurrentTheme] = useState<string>(hrLandingFormOptions[0].value);

    const handleOpen = () => {
        setIsModalOpen(true);
    };

    const handleOpenWithoutTheme = () => {
        setCurrentTheme(hrLandingFormOptions[0].value);
        setIsModalOpen(true);
    };

    const handleOpenWithTheme = (theme: string) => {
        setCurrentTheme(theme);
        setIsModalOpen(true);
    };

    if (!Feature.isFeatureEnabled('enableHRLanding')) {
        return null;
    }

    return (
        <div className={clsx(cn.layout)}>
            <HRHeader handler={handleOpenWithoutTheme} menu={menu} hrGtag={hrGtag} />

            <div className={cn.content}>
                <HRMain {...hrMain} />
                <HRConditions hrConditionsList={hrConditionsList} />
                <HRRequirements hrRequirementsList={hrRequirementsList} handler={handleOpenWithTheme} hrGtag={hrGtag} />
                <HRDetailConditions title="Условия" hrDetailConditionsList={hrDetailConditionsList} />
                <HRStage hrStageList={hrStageList} />
                <HRInternship hrInternshipList={hrInternshipList} />
                <HRDemo />
                <HRFaq hrFaqList={hrFaqList} handler={handleOpenWithoutTheme} />
            </div>

            <HRFooter handler={handleOpenWithoutTheme} menu={menu} />

            <ModalWrapper
                isOpen={isModalOpen}
                handleClose={() => setIsModalOpen(false)}
                handleOpen={handleOpen}
                wrapperClassName={cn.modalWrapper}
                bgClassName={cn.modalOverlay}
                className={cn.modalInner}
            >
                <HRLandingForm
                    title="Заявка на стажировку"
                    defaultValue={currentTheme}
                    aboutOptions={aboutOptions}
                    hrLandingFormOptions={hrLandingFormOptions}
                    levelStudyOptions={levelStudyOptions}
                    hrGtag={hrGtag}
                />
            </ModalWrapper>
        </div>
    );
}

export default withBlockLinks(HRLanding);
