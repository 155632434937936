import Image from 'next/image';
import React, { useRef, useState } from 'react';

import ModalWrapper from '@/components/modal/ModalWrapper';
import useOnClickOutside from '@/hooks/useOnClickOutside';

import cn from './style.module.sass';

function HRDemo() {
    const [isOpen, setIsOpen] = useState(false);

    const ref = useRef(null);

    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    useOnClickOutside(ref, handleClose);
    return (
        <div className={cn.demo}>
            <div className={cn.wrap}>
                <div className={cn.demoBlock}>
                    <div className={cn.demoHeader}>Как проходит стажировка</div>
                    <div className={cn.demoVideo} ref={ref} onClick={handleOpen} role="presentation">
                        <div className={cn.demoWrapper}>
                            <Image
                                src="/img-next/jpg/hr-landing/demo-promo.jpg"
                                width="928"
                                height="523"
                                alt="promoImage"
                            />
                            <div className={cn.inner}>
                                <button type="button" className={cn.button}>
                                    <Image
                                        src="/img-next/svg/hr-landing/hr-demo/videoPlay.svg"
                                        width={29}
                                        height={29}
                                        alt="youTube"
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                    <ModalWrapper
                        isOpen={isOpen}
                        handleClose={handleClose}
                        handleOpen={handleOpen}
                        wrapperClassName={cn.modalWrapper}
                        bgClassName={cn.modalOverlay}
                        className={cn.modalInner}
                    >
                        <div className={cn.videoWrapper}>
                            <iframe
                                width="560"
                                height="315"
                                src="https://www.youtube.com/embed/_KZDgcYqiN0"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen
                            />
                        </div>
                    </ModalWrapper>
                </div>
            </div>
        </div>
    );
}

export default HRDemo;
