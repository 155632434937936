import clsx from 'clsx';
import React from 'react';

import cn from './style.module.sass';

interface IProps {
    name: string;
}

function HRRequirementsCategory({ name }: IProps) {
    return <div className={clsx(cn.hrRequirementsCategory)}>{name}</div>;
}

export default HRRequirementsCategory;
