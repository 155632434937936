import React from 'react';

import HRFaqItem from '@/components/blocks/HRLanding/HRFaq/HRFaqItem';

import cn from './style.module.sass';

interface IHRFaqList {
    hrFaqList: IHRFaqItem[];
    handler?: () => void;
}

interface IHRFaqItem {
    title: string;
    subtitle: string;
}

function HRFaq({ hrFaqList, handler }: IHRFaqList) {
    return (
        <div className={cn.faq} id="faq">
            <div className={cn.wrap}>
                <div className={cn.faqBlock}>
                    <div className={cn.faqHeader}>FAQ</div>
                    <div className={cn.faqList}>
                        {hrFaqList.map(({ title, subtitle }, index) => (
                            <HRFaqItem title={title} subtitle={subtitle} key={index} handler={handler} />
                        ))}
                    </div>
                    <div className={cn.faqBottom}>
                        <div className={cn.faqBottomQuestion}>Остались вопросы?</div>
                        <div className={cn.faqBottomEmail}>
                            Напишите нам на <a href="mailto:job@pochtabank.ru">job@pochtabank.ru</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HRFaq;
