import { ISocialItem } from '@/types/footer';

export const socialHR: ISocialItem[] = [
    {
        image: '/img-next/svg/social/vk.svg',
        name: 'VK',
        link: 'https://vk.com/pochtabank',
        gtag: {
            action: 'click',
            category: 'Interactions',
            label: 'main__footer_vk',
        },
    },
    {
        image: '/img-next/svg/social/ok.svg',
        name: 'OK',
        link: 'https://ok.ru/pochtabank',
        gtag: {
            category: 'Interactions',
            label: 'main__footer_ok',
            location: '',
            action: 'click',
        },
    },
    {
        image: 'https://pochtabank-upload-prod.storage.yandexcloud.net/medialibrary/50c/50c800c7d2558b9d6f884c231c44ff64/6e6a9d4345695dc26fbf6e431dcb8c04.png?id=6302',
        name: 'Dzen',
        link: 'https://zen.yandex.ru/pochtabank',
    },
    {
        image: 'https://pochtabank-upload-prod.storage.yandexcloud.net/medialibrary/c72/c726c9c6591a0841c9f40465105a552e/372aaff964c55e58637f88e6ca9fd54f.png?id=6303',
        name: 'YouTube',
        link: 'https://www.youtube.com/c/%D0%9F%D0%BE%D1%87%D1%82%D0%B0%D0%91%D0%B0%D0%BD%D0%BA',
    },
    {
        image: 'https://pochtabank-upload-prod.storage.yandexcloud.net/medialibrary/68c/68c53c5e79132d7211e4b798c55803eb/d67642257b4407a4daa97a03f7edc1c9.png?id=6304',
        name: 'Telegram',
        link: 'https://t.me/pochtabank',
    },
];
