import clsx from 'clsx';
import React from 'react';

import HRRequirementsSlider from '@/components/blocks/HRLanding/HRRequirements/HRRequirementsSlider';
import { IHRRequirements } from '@/components/blocks/HRLanding/types/hrRequirements';

import cn from './style.module.sass';

function HRRequirements({ hrRequirementsList, hrGtag, handler }: IHRRequirements) {
    return (
        <div className={clsx(cn.hrRequirements, 'hrIndent')} id="direction">
            <div className={cn.wrap}>
                <div className={cn.hrRequirementsBlock}>
                    <HRRequirementsSlider hrRequirementsList={hrRequirementsList} handler={handler} hrGtag={hrGtag} />
                </div>
            </div>
        </div>
    );
}

export default HRRequirements;
