import React from 'react';

import cn from '@/components/blocks/HRLanding/style.module.sass';

interface IProps {
    handleClick?: () => void;
}

export function BurgerIcon({ handleClick }: IProps) {
    return (
        <div onClick={handleClick} role="presentation">
            <svg
                width="37"
                height="22"
                viewBox="0 0 37 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={cn.icon}
            >
                <rect x="0.472656" width="36" height="2" />
                <rect x="0.472656" y="10" width="36" height="2" />
                <rect x="0.472656" y="20" width="36" height="2" />
            </svg>
        </div>
    );
}
