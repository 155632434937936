import clsx from 'clsx';
import React from 'react';

import CustomImage from '@/components/CustomImage';

import cn from './style.module.sass';

interface IHRInternshipList {
    hrInternshipList: IHRInternshipItem[];
}

interface IHRInternshipItem {
    icon: string;
    title: string;
}

function HRInternship({ hrInternshipList }: IHRInternshipList) {
    return (
        <div className={clsx(cn.internship, 'hrIndent')}>
            <div className={cn.wrap}>
                <div className={cn.internshipBlock}>
                    <div className={cn.internshipHeader}>Стажировка у нас это</div>
                    <div className={cn.internshipList}>
                        {hrInternshipList.map((item, idx) => (
                            <div className={cn.internshipInner} key={idx}>
                                <div className={cn.internshipItem}>
                                    <CustomImage
                                        className={cn.internshipIcon}
                                        width={35}
                                        height={35}
                                        src={item.icon}
                                        alt="internshipIcon"
                                    />
                                    <div
                                        className={cn.internshipTitle}
                                        dangerouslySetInnerHTML={{ __html: item.title }}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HRInternship;
