import clsx from 'clsx';
import { useSearchParams } from 'next/navigation';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import RadioInputWrapper from '@/components/blocks/forms/UI/RadioInputWrapper';
import Button from '@/components/UI/Button';
import Checkbox from '@/components/UI/Checkbox';
import DatePickerInput from '@/components/UI/DatePicker';
import { FileUpload } from '@/components/UI/FileUpload';
import Input from '@/components/UI/Input';
import TextArea from '@/components/UI/TextArea';
import Title from '@/components/UI/Title';
import { InnerFormValues, useValidationSchema } from '@/hooks/useValidationSchema';
import { IBXResponseForms } from '@/types/requests';
import { ISelectElement } from '@/types/select';
import { getFormDataFromHookForm } from '@/utils/forms/getFormDataFromHookForm';
import { getFormErrors } from '@/utils/forms/getFormErrors';
import { GTagEvent, universalEvent } from '@/components/shared/utilities/analytics/metrics';
import { removePropertyFromObject } from '@/utils/removePropertyFromObject';
import { apiRequest } from '@/utils/request';

import cn from './style.module.sass';

export interface IHRLandingForm {
    _template?: 'hrLandingForm';
    title?: string;
    defaultValue?: string;
    aboutOptions: ISelectElement<string>[];
    hrLandingFormOptions: ISelectElement<string>[];
    levelStudyOptions: ISelectElement<string>[];
    hrGtag?: {
        [key: string]: GTagEvent;
    };
}

function HRLandingForm({
    title,
    defaultValue,
    _template = 'hrLandingForm',
    hrLandingFormOptions,
    levelStudyOptions,
    aboutOptions,
    hrGtag,
}: IHRLandingForm) {
    const [isFail, setIsFail] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const searchParams = useSearchParams();
    const fileTypes = ['.doc', '.pdf'];

    const formResolver = useValidationSchema([
        { name: 'accept', type: 'checkbox', required: true },
        { name: 'direction', type: 'required', required: true },
        { name: 'fio', type: 'fio', required: true },
        { name: 'dateBirth', type: 'birthDate', required: true },
        { name: 'phone', type: 'phone', required: true },
        { name: 'email', type: 'email', required: true },
        { name: 'formStudy', type: 'required', required: true },
        { name: 'level', type: 'required', required: true },
        { name: 'university', type: 'required', required: true },
        { name: 'department', type: 'required', required: true },
        { name: 'speciality', type: 'required', required: true },
        { name: 'dateEnd', type: 'required', required: true },
    ]);

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
        control,
        setValue,
    } = useForm<InnerFormValues>({ resolver: formResolver });

    const methods = useForm<InnerFormValues>({
        resolver: formResolver,
    });

    // Регистрация полей для файла, обязательно, так как ref занят useDropzone
    useEffect(() => {
        register('file');
    }, [register]);

    const onSubmit = async finalData => {
        const utmSource = searchParams.get('utm_source') || undefined;
        const utmMedium = searchParams.get('utm_medium') || undefined;
        const utmCampaing = searchParams.get('utm_campaing') || undefined;
        const utmTerm = searchParams.get('utm_term') || undefined;
        const pagePath = typeof window !== undefined ? window.location.href : undefined;

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const newDate = new Date(finalData.dateBirth).toDateString();

        const filteredFinalData = removePropertyFromObject(finalData, 'accept');
        const formData = getFormDataFromHookForm({
            ...filteredFinalData,
            dateBirth: newDate,
            utm_source: utmSource,
            utm_medium: utmMedium,
            utm_campaing: utmCampaing,
            utm_term: utmTerm,
            pagePath,
        });

        try {
            const response = await apiRequest.post<IBXResponseForms<{ message: string }>>(
                `/forms/${_template}/send`,
                formData
            );

            if (response?.data?.errors.length > 0) {
                setIsFail(true);
                throw new Error(response?.data?.errors[0].message);
            } else {
                setIsSuccess(true);

                universalEvent(hrGtag.gtagForm);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const [isErrorAccept, errorAcceptText] = getFormErrors(errors, 'accept');
    const [isErrorDirection, errorDirectionText] = getFormErrors(errors, 'direction');
    const [isErrorFio, errorFioText] = getFormErrors(errors, 'fio');
    const [isErrorDateBirth, errorDateBirthText] = getFormErrors(errors, 'dateBirth');
    const [isErrorPhone, errorPhoneText] = getFormErrors(errors, 'phone');
    const [isErrorEmail, errorEmailText] = getFormErrors(errors, 'email');
    const [isErrorFormStudy, errorFormStudyText] = getFormErrors(errors, 'formStudy');
    const [isErrorLevel, errorLevelText] = getFormErrors(errors, 'level');
    const [isErrorUniversity, errorUniversityText] = getFormErrors(errors, 'university');
    const [isErrorDepartment, errorDepartmentText] = getFormErrors(errors, 'department');
    const [isErrorSpeciality, errorSpecialityText] = getFormErrors(errors, 'speciality');
    const [isErrorDateEnd, errorDateEndText] = getFormErrors(errors, 'dateEnd');

    return (
        <FormProvider {...methods}>
            <div>
                {!isFail && !isSuccess && (
                    <form className={cn.form} onSubmit={handleSubmit(onSubmit)} noValidate>
                        <Title title={title} level={2} className={cn.title} />

                        <div>
                            <RadioInputWrapper
                                errors={errors}
                                isError={isErrorDirection}
                                errorText={errorDirectionText}
                                {...register('direction')}
                                item={{
                                    label: 'Направление *',
                                    innerList: [
                                        {
                                            type: 'radio',
                                            label: 'Аналитик',
                                            value: 'Аналитик',
                                            name: 'direction',
                                            active: defaultValue === 'Аналитик',
                                        },
                                        {
                                            type: 'radio',
                                            label: 'CRM',
                                            value: 'CRM',
                                            name: 'direction',
                                            active: defaultValue === 'CRM',
                                        },
                                        {
                                            type: 'radio',
                                            label: 'Бизнес процессы',
                                            value: 'Бизнес процессы',
                                            name: 'direction',
                                            active: defaultValue === 'Бизнес процессы',
                                        },
                                        {
                                            type: 'radio',
                                            label: 'Управление проектами',
                                            value: 'Управление проектами',
                                            name: 'direction',
                                            active: defaultValue === 'Управление проектами',
                                        },
                                        {
                                            type: 'radio',
                                            label: 'QA',
                                            value: 'QA',
                                            name: 'direction',
                                            active: defaultValue === 'QA',
                                        },
                                        {
                                            type: 'radio',
                                            label: 'Функциональная архитектура',
                                            value: 'Функциональная архитектура',
                                            name: 'direction',
                                            active: defaultValue === 'Функциональная архитектура',
                                        },
                                        {
                                            type: 'radio',
                                            label: 'Тестирование',
                                            value: 'Тестирование',
                                            name: 'direction',
                                            active: defaultValue === 'Тестирование',
                                        },
                                        {
                                            type: 'radio',
                                            label: 'DWH',
                                            value: 'DWH',
                                            name: 'direction',
                                            active: defaultValue === 'DWH',
                                        },
                                    ],
                                }}
                            />
                        </div>

                        <div className={cn.input}>
                            <Input
                                type="text"
                                label="ФИО *"
                                shouldColored
                                customClass={cn.isFull}
                                isError={isErrorFio}
                                errorText={errorFioText}
                                {...register('fio')}
                            />
                        </div>

                        <div className={cn.input}>
                            <Controller
                                control={control}
                                name="dateBirth"
                                render={({ field }) => (
                                    <DatePickerInput
                                        {...field}
                                        name={field.name}
                                        label="Дата рождения *"
                                        isError={isErrorDateBirth}
                                        errorText={errorDateBirthText}
                                    />
                                )}
                            />
                        </div>

                        <div className={clsx(cn.input, cn.inputMask)}>
                            <Input
                                type="tel"
                                label="Телефон *"
                                mask="+7 (999) 999-99-99"
                                shouldColored
                                customClass={cn.isFull}
                                isError={isErrorPhone}
                                errorText={errorPhoneText}
                                {...register('phone')}
                            />
                        </div>
                        <div className={cn.input}>
                            <Input
                                isError={isErrorEmail}
                                errorText={errorEmailText}
                                shouldColored
                                customClass={cn.isFull}
                                type="text"
                                label="E-mail *"
                                {...register('email')}
                            />
                        </div>

                        <div>
                            <RadioInputWrapper
                                errors={errors}
                                isError={isErrorFormStudy}
                                errorText={errorFormStudyText}
                                {...register('formStudy')}
                                item={{
                                    label: 'Форма обучения *',
                                    innerList: [
                                        {
                                            type: 'radio',
                                            label: 'Очно',
                                            value: 'Очно',
                                            name: 'formStudy',
                                            active: true,
                                        },
                                        {
                                            type: 'radio',
                                            label: 'Заочно',
                                            value: 'Заочно',
                                            name: 'formStudy',
                                        },
                                    ],
                                }}
                            />
                        </div>

                        <div>
                            <RadioInputWrapper
                                errors={errors}
                                isError={isErrorLevel}
                                errorText={errorLevelText}
                                {...register('level')}
                                item={{
                                    label: 'Cтупень обучения *',
                                    innerList: [
                                        {
                                            type: 'radio',
                                            label: '1 курс',
                                            value: '1 курс',
                                            name: 'level',
                                            active: true,
                                        },
                                        {
                                            type: 'radio',
                                            label: '2 курс',
                                            value: '2 курс',
                                            name: 'level',
                                        },
                                        {
                                            type: 'radio',
                                            label: '3 курс',
                                            value: '3 курс',
                                            name: 'level',
                                        },
                                        {
                                            type: 'radio',
                                            label: '4 курс',
                                            value: '4 курс',
                                            name: 'level',
                                        },
                                        {
                                            type: 'radio',
                                            label: 'магистратура',
                                            value: 'магистратура',
                                            name: 'level',
                                        },
                                        {
                                            type: 'radio',
                                            label: 'аспирантура',
                                            value: 'аспирантура',
                                            name: 'level',
                                        },
                                        {
                                            type: 'radio',
                                            label: 'выпускник',
                                            value: 'выпускник',
                                            name: 'level',
                                        },
                                        {
                                            type: 'radio',
                                            label: 'другое',
                                            value: 'другое',
                                            name: 'level',
                                        },
                                    ],
                                }}
                            />
                        </div>

                        <div className={cn.input}>
                            <Input
                                isError={isErrorUniversity}
                                errorText={errorUniversityText}
                                shouldColored
                                customClass={cn.isFull}
                                type="text"
                                label="Университет *"
                                {...register('university')}
                            />
                        </div>

                        <div className={cn.input}>
                            <Input
                                isError={isErrorDepartment}
                                errorText={errorDepartmentText}
                                shouldColored
                                customClass={cn.isFull}
                                type="text"
                                label="Кафедра *"
                                {...register('department')}
                            />
                        </div>

                        <div className={cn.input}>
                            <Input
                                isError={isErrorSpeciality}
                                errorText={errorSpecialityText}
                                shouldColored
                                customClass={cn.isFull}
                                type="text"
                                label="Специальность *"
                                {...register('speciality')}
                            />
                        </div>

                        <div className={cn.input}>
                            <Input
                                isError={isErrorDateEnd}
                                errorText={errorDateEndText}
                                shouldColored
                                customClass={cn.isFull}
                                type="text"
                                label="Год окончания *"
                                {...register('dateEnd')}
                            />
                        </div>

                        <div className={cn.input}>
                            <TextArea
                                placeholder="Учебные интересы ( Тема вашей курсовой работы, учебные проекты, дисциплины, которые вам больше всего понравились )"
                                {...register('interests')}
                            />
                        </div>

                        <div className={cn.input}>
                            <TextArea
                                placeholder="Учебные достижения ( Медали, сертификаты, об участии в олимпиадах, хакатонах, соревнованиях: )"
                                {...register('achievements')}
                            />
                        </div>

                        <div className={cn.input}>
                            <TextArea
                                placeholder="Пример вашего кода ( Ссылка на GitHub, Bitbucket или на другой подобный сайт )"
                                {...register('codeExamples')}
                            />
                        </div>

                        <div className={cn.input}>
                            <TextArea
                                placeholder="Ваши ожидания от стажировки ( Какими задачами вам было бы интересно заниматься во время стажировки )"
                                {...register('expectations')}
                            />
                        </div>

                        <div className={cn.input}>
                            <TextArea
                                placeholder="Технические навыки ( Перечислите какими техническими навыками вы обладаете, какие технологии, языки программирования знаете, использовали в рабочих\личных проектах )"
                                {...register('skills')}
                            />
                        </div>

                        <div>
                            <RadioInputWrapper
                                errors={errors}
                                {...register('about')}
                                item={{
                                    label: 'Откуда вы узнали о стажировке',
                                    innerList: [
                                        {
                                            type: 'radio',
                                            label: 'Рекламные публикации в социальных сетях',
                                            value: 'Рекламные публикации в социальных сетях',
                                            name: 'about',
                                            active: true,
                                        },
                                        {
                                            type: 'radio',
                                            label: 'в ВУЗе',
                                            value: 'в ВУЗе',
                                            name: 'about',
                                        },
                                        {
                                            type: 'radio',
                                            label: 'от друзей',
                                            value: 'от друзей',
                                            name: 'about',
                                        },
                                        {
                                            type: 'radio',
                                            label: 'через поисковик в интернете',
                                            value: 'через поисковик в интернете',
                                            name: 'about',
                                        },
                                        {
                                            type: 'radio',
                                            label: 'другое',
                                            value: 'другое',
                                            name: 'about',
                                        },
                                    ],
                                }}
                            />
                        </div>

                        <div className={cn.input}>
                            <FileUpload
                                onFileChange={(files: File[]) => setValue('file', files)}
                                label="Добавить резюме"
                                fileTypes={fileTypes}
                            />
                        </div>

                        <div className={cn.input}>
                            <Checkbox
                                size="small"
                                isError={isErrorAccept}
                                errorText={errorAcceptText}
                                label='Даю согласие на <a href="https://www.pochtabank.ru/personal-data?_ga=2.169039207.773343870.1681377168-720124909.1675855140">обработку персональных данных</a>'
                                {...register('accept')}
                            />
                        </div>

                        <Button
                            variant="btnBlue"
                            buttonType="submit"
                            type="button"
                            label="Отправить заявку"
                            disable={isSubmitting}
                            customClass={cn.button}
                            animation={false}
                        />
                    </form>
                )}

                {isFail && (
                    <Title
                        title="К сожалению, произошла ошибка при отправке формы. Попробуйте позднее."
                        level={2}
                        className={cn.title}
                    />
                )}

                {isSuccess && <Title title="Форма успешно отправлена!" level={2} className={cn.title} />}
            </div>
        </FormProvider>
    );
}

export default HRLandingForm;
