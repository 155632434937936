import clsx from 'clsx';
import React from 'react';
import { useIntersection } from 'react-use';

import { IHRStageItem } from '@/components/blocks/HRLanding/types/hrStage';

import cn from './style.module.sass';

function HRStageItem({ number, stage, description }: IHRStageItem) {
    const hrStageItemRef = React.useRef(null);
    const intersection = useIntersection(hrStageItemRef, {
        root: null,
        rootMargin: '-50% 0% -50% 0%',
        threshold: 0,
    });
    const isVisible = !!intersection?.isIntersecting;

    return (
        <div className={cn.hrStageItem} ref={hrStageItemRef} id={number}>
            <div className={cn.hrStageStep}>
                <div
                    className={clsx(cn.hrStageCircle, {
                        [cn.hrStageCircleActive]: isVisible && intersection?.target.id === number,
                    })}
                />
                <div className={cn.hrStageNumber}>
                    <span>/</span>
                    {number}
                </div>
            </div>
            <div className={cn.hrStageInfo}>
                <div className={cn.hrStageText} dangerouslySetInnerHTML={{ __html: stage }} />
                <div className={cn.hrStageDescription} dangerouslySetInnerHTML={{ __html: description }} />
            </div>
        </div>
    );
}

export default HRStageItem;
