export const hrStageList = [
    {
        number: '01',
        stage: 'Подача заявки',
        description: 'Заполните форму заявки здесь<br/>Заявки принимаются до 9 июня 2023',
    },
    {
        number: '02',
        stage: 'Знакомство с&nbsp;рекрутером',
        description: 'Пройдите краткое собеседование онлайн',
    },
    {
        number: '03',
        stage: 'Выполнение тестового задания',
        description: 'У&nbsp;вас есть 1&nbsp;неделя на&nbsp;выполнение,<br/>оцениваются качество и&nbsp;скорость',
    },
    {
        number: '04',
        stage: 'Техническое собеседование',
        description: 'Пройдите собеседование с&nbsp;будущим руководителем',
    },
    {
        number: '05',
        stage: 'Оформление на&nbsp;стажировку',
        description: 'Подпишите договор и&nbsp;вы&nbsp;в&nbsp;Команде! <br/>Стажировка начинается 03&nbsp;июля 2023',
    },
];
