import clsx from 'clsx';
import React from 'react';

import HRStageItem from '@/components/blocks/HRLanding/HRStage/HRStageItem';
import { IHRStageList } from '@/components/blocks/HRLanding/types/hrStage';

import cn from './style.module.sass';

function HRStage({ hrStageList }: IHRStageList) {
    return (
        <div className={clsx(cn.hrStage, 'hrIndent')} id="stages">
            <div className={cn.wrap}>
                <div className={cn.hrStageBlock}>
                    <div className={cn.hrStageHeader}>Отбор состоит из&nbsp;5&nbsp;этапов</div>
                    <div className={cn.hrStageList}>
                        {hrStageList.map((item, index) => (
                            <HRStageItem
                                number={item.number}
                                stage={item.stage}
                                description={item.description}
                                key={index}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HRStage;
