export const hrDetailConditionsList = [
    {
        icon: '/img-next/svg/hr-landing/hr-detail-conditions/format.svg',
        title: 'ФОРМАТ',
        subtitle: 'Очно в&nbsp;Центральном офисе',
    },
    {
        icon: '/img-next/svg/hr-landing/hr-detail-conditions/duration.svg',
        title: 'ПРОДОЛЖИТЕЛЬНОСТЬ',
        subtitle: '3&nbsp;месяца (июль-сентябрь) + 4&nbsp;месяца как приятный бонус',
    },
    {
        icon: '/img-next/svg/hr-landing/hr-detail-conditions/graphic.svg',
        title: 'ГРАФИК',
        subtitle:
            'Полный рабочий день, 40&nbsp;ч в&nbsp;неделю летом и&nbsp;не&nbsp;менее 20&nbsp;ч в&nbsp;неделю с&nbsp;1&nbsp;сентября',
    },
    {
        icon: '/img-next/svg/hr-landing/hr-detail-conditions/salary.svg',
        title: 'ЗАРПЛАТА',
        subtitle: '55&nbsp;000&nbsp;₽ Gross',
    },
    {
        icon: '/img-next/svg/hr-landing/hr-detail-conditions/decor.svg',
        title: 'ОФОРМЛЕНИЕ',
        subtitle: 'Официальное оформление на&nbsp;срочный договор',
    },
    {
        icon: '/img-next/svg/hr-landing/hr-detail-conditions/projects.svg',
        title: 'ПРОЕКТЫ',
        subtitle: 'Погружение в&nbsp;задачи и&nbsp;участие в&nbsp;реальных проектах',
    },
    {
        icon: '/img-next/svg/hr-landing/hr-detail-conditions/teacher.svg',
        title: 'НАСТАВНИК',
        subtitle: 'Поддерживающие встречи с&nbsp;наставником за&nbsp;пиццей',
    },
    {
        icon: '/img-next/svg/hr-landing/hr-detail-conditions/education.svg',
        title: 'ОБУЧЕНИЕ',
        subtitle: 'Тренинги по&nbsp;soft-навыкам и&nbsp;очень много hard!',
    },
    {
        icon: '/img-next/svg/hr-landing/hr-detail-conditions/career.svg',
        title: 'КАРЬЕРНЫЙ СТАРТ',
        subtitle: 'Возможность продолжить карьеру в&nbsp;стабильном банке',
    },
];
