import React from 'react';

import cn from '../HRFaqItem/style.module.sass';

function HRArrow() {
    return (
        <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1 1L11 11L21 1"
                className={cn.svgStroke}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default HRArrow;
