import clsx from 'clsx';
import React from 'react';

import cn from './style.module.sass';

interface IHRMenuItem {
    title: string;
    id?: string;
}

export interface IHRMenu {
    list: IHRMenuItem[];
    closeMenu?: () => void;
}

function HRMenu({ list, closeMenu }: IHRMenu) {
    const onClick = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>, id?: string) => {
        e.preventDefault();
        if (closeMenu) {
            closeMenu();
        }
        const element = document.getElementById(id);

        if (element) {
            element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
    };

    return (
        <div className={clsx(cn.menu)}>
            {list?.map((item, index) => (
                <div className={cn.item} key={item.id}>
                    <a href="/" onClick={event => onClick(event, item.id)}>
                        {item.title}
                    </a>
                </div>
            ))}
        </div>
    );
}

export default HRMenu;
