export const hrRequirementsList = [
    {
        id: '1',
        name: 'DWH',
        list: [
            {
                title: 'Стажер в службу по управлению данными',
                info: [
                    {
                        title: 'С&nbsp;ЧЕМ БУДЕТЕ РАБОТАТЬ:',
                        subtitle: [
                            'Своевременно обрабатывать обращения по поддержке хранилища данных 1-ой линии',
                            'Актуализировать документы по поддержке хранилища данных',
                        ],
                    },
                    {
                        title: 'ОЖИДАЕМ:',
                        subtitle: [
                            'Желание развиваться в направлении финансового сектора и управления данными',
                            'Ответственно подходить к поставленным задачам',
                        ],
                    },
                ],
            },
        ],
    },
    {
        id: '2',
        name: 'Аналитик',
        list: [
            {
                title: 'Стажер в направление мониторинга аналитики и отчетности',
                info: [
                    {
                        title: 'С&nbsp;ЧЕМ БУДЕТЕ РАБОТАТЬ:',
                        subtitle: [
                            'Формировать и обновлять операционную отчётность',
                            'Подготавливать реестры',
                            'Работать с простыми аналитическими задачами',
                        ],
                    },

                    {
                        title: 'ОЖИДАЕМ:',
                        subtitle: [
                            'Желание развиваться в направлении аналитики и погружаться в банковские процессы',
                            'Начальные навыки SQL, Excel и Power Point',
                        ],
                    },
                ],
            },

            {
                title: 'Стажер в клиентскую службу',
                info: [
                    {
                        title: 'С&nbsp;ЧЕМ БУДЕТЕ РАБОТАТЬ:',
                        subtitle: [
                            'Формировать аналитическую отчетность по операционным и стратегическим показателям работы клиентской службы',
                            'Автоматизировать отчетность клиентской службы',
                            'Формировать предложения по оптимизации процессов с целью улучшения клиентского опыта и сокращения затрат на обслуживание',
                        ],
                    },
                    {
                        title: 'ОЖИДАЕМ:',
                        subtitle: [
                            'Желание развиваться в направлении аналитики и погружаться в банковские процессы',
                            'Опыт работы с языками программирования и инструментами для построения отчетности',
                        ],
                    },
                ],
            },

            {
                title: 'Стажер в центр анализа и управления стратегией ',
                info: [
                    {
                        title: 'С&nbsp;ЧЕМ БУДЕТЕ РАБОТАТЬ:',
                        subtitle: [
                            'Анализировать операционные показатели',
                            'Мониторить выполнение планов подразделений',
                            'Писать sql-скрипты',
                            'Создавать презентации',
                        ],
                    },

                    {
                        title: 'ОЖИДАЕМ:',
                        subtitle: [
                            'Желание развиваться в направлении анализа данных',
                            'Высокий уровень SQL',
                            'Умение работать с Excel (ВПР, сводные таблицы)',
                        ],
                    },
                ],
            },

            {
                title: 'Стажер в центр управленческой отчетности и финансового анализа',
                info: [
                    {
                        title: 'С&nbsp;ЧЕМ БУДЕТЕ РАБОТАТЬ:',
                        subtitle: [
                            'Разрабатывать управленческие отчеты для бизнес-подразделений банка',
                            'Выгружать данные с помощью sql-запросов',
                        ],
                    },
                    {
                        title: 'ОЖИДАЕМ:',
                        subtitle: [
                            'Желание работать с базами данных и создавать аналитические отчеты',
                            'Базовый уровень SQL, Excel',
                        ],
                    },
                ],
            },
            {
                title: 'Стажер в отдел электронной аналитики ',
                info: [
                    {
                        title: 'С&nbsp;ЧЕМ БУДЕТЕ РАБОТАТЬ:',
                        subtitle: [
                            'Писать SQL-запросы',
                            'Поддерживать отчетность',
                            'Писать программы на Python',
                            'Анализировать банковские показатели',
                        ],
                    },
                    {
                        title: 'ОЖИДАЕМ:',
                        subtitle: [
                            'Желание развиваться в направлении анализа данных',
                            'Уверенное знание SQL, Excel',
                            'Базовое знание Python будет плюсом',
                        ],
                    },
                ],
            },

            {
                title: 'Стажер в дирекцию дистанционных продаж',
                info: [
                    {
                        title: 'С&nbsp;ЧЕМ БУДЕТЕ РАБОТАТЬ:',
                        subtitle: [
                            'Анализировать процесс дистанционных продаж',
                            'Заниматься ad hoc-аналитикой по пилотам, связанным с дистанционными продажами',
                        ],
                    },
                    {
                        title: 'ОЖИДАЕМ:',
                        subtitle: [
                            'Желание развиваться в направлении анализа данных',
                            'Уверенное знание Excel',
                            'Аналитический склад ума',
                        ],
                    },
                ],
            },

            {
                title: 'Стажер в&nbsp;Отдел бюджетирования и&nbsp;отчетности',
                info: [
                    {
                        title: 'С&nbsp;ЧЕМ БУДЕТЕ РАБОТАТЬ:',
                        subtitle: [
                            'Формировать регулярную отчетность на&nbsp;базе MS&nbsp;SQL и&nbsp;Power&nbsp;BI',
                            'Выгружать информацию из&nbsp;различных источников, обрабатывать и&nbsp;форматировать, загружать в&nbsp;базу данных',
                            'Подготавливать данные по&nbsp;численности персонала по&nbsp;запросам',
                            'Участвовать в&nbsp;формировании ресурсного плана, вносить изменения в&nbsp;ресурсный план',
                        ],
                    },
                    {
                        title: 'ОЖИДАЕМ:',
                        subtitle: [
                            'Желание развиваться в&nbsp;направлении анализа данных',
                            'Уверенный пользователь Excel',
                            'Базовый уровень владения SQL',
                            'Знание принципов формирования баз данных (желательно MS SQL)',
                        ],
                    },
                ],
            },
        ],
    },
    {
        id: '3',
        name: 'CRM',
        list: [
            {
                title: 'Стажер в дирекцию портфельных кампаний и банковских программ лояльности',
                info: [
                    {
                        title: 'С&nbsp;ЧЕМ БУДЕТЕ РАБОТАТЬ:',
                        subtitle: [
                            'Анализировать клиентскую базу',
                            'Отбирать сегменты для таргетированных CRM-предложений',
                            'Подготавливать и запускать коммуникации с клиентами Банка',
                        ],
                    },
                    {
                        title: 'ОЖИДАЕМ:',
                        subtitle: [
                            'Желание развиваться в направлении аналитики и погружаться в банковские процессы',
                            'Начальные навыки SQL',
                        ],
                    },
                ],
            },
            {
                title: 'Стажер в дирекцию целевого маркетинга',
                info: [
                    {
                        title: 'С&nbsp;ЧЕМ БУДЕТЕ РАБОТАТЬ:',
                        subtitle: [
                            'Подготавливать бизнес-требования на запуск пилотных кампаний по продажам',
                            'Анализировать запущенные пилоты',
                            'Подготавливать маркетинговые коммуникации для проведения кампаний',
                            'Анализировать воронку по кредитным продуктам банка',
                        ],
                    },
                    {
                        title: 'ОЖИДАЕМ:',
                        subtitle: [
                            'Желание развиваться в банковской сфере в направлении анализа данных',
                            'Базовый уровень SQL',
                            'Умение структурировать полученную информацию',
                        ],
                    },
                ],
            },
        ],
    },
    {
        id: '4',
        name: 'Бизнес процессы',
        list: [
            {
                title: 'Стажер в направление по анализу эффективности бизнес-процессов',
                info: [
                    {
                        title: 'С&nbsp;ЧЕМ БУДЕТЕ РАБОТАТЬ:',
                        subtitle: [
                            'Анализировать бизнес-процессы, рынок',
                            'Заниматься построением клиентских путей',
                            'Искать решения для улучшения бизнес-процессов',
                            'Анализировать данные',
                        ],
                    },
                    {
                        title: 'ОЖИДАЕМ:',
                        subtitle: [
                            'Студентов с экономическим профилем обучения',
                            'Интерес к консалтингу, бизнес-процессам, анализу и финансовому направлению',
                        ],
                    },
                ],
            },
        ],
    },
    {
        id: '5',
        name: 'Управление проектами',
        list: [
            {
                title: 'Стажер в отдел ИТ проектов',
                info: [
                    {
                        title: 'С&nbsp;ЧЕМ БУДЕТЕ РАБОТАТЬ:',
                        subtitle: ['Вести портфель проектов и задач', 'Заниматься администрированием проектов'],
                    },
                    {
                        title: 'ОЖИДАЕМ:',
                        subtitle: [
                            'Студентов с профилем обучения Управление проектами или менеджмент',
                            'Интерес в направлении ИТ или финансов',
                        ],
                    },
                ],
            },
            {
                title: 'Стажер в службу электронного бизнеса',
                info: [
                    {
                        title: 'С&nbsp;ЧЕМ БУДЕТЕ РАБОТАТЬ:',
                        subtitle: ['Вести портфель проектов и задач', 'Заниматься администрированием проектов'],
                    },
                    {
                        title: 'ОЖИДАЕМ:',
                        subtitle: [
                            'Желание развиваться в банковской сфере в направлении анализа данных',
                            'Знание Word, Excel, Power Point',
                            'Умение структурировать полученную информацию',
                        ],
                    },
                ],
            },
        ],
    },
    {
        id: '6',
        name: 'QA',
        list: [
            {
                title: 'Стажер в отдел тестирования систем электронного бизнеса',
                info: [
                    {
                        title: 'С&nbsp;ЧЕМ БУДЕТЕ РАБОТАТЬ:',
                        subtitle: [
                            'Работать с полным циклом тестирования от составления тестовых сценариев до запуска проектов в эксплуатацию',
                        ],
                    },
                    {
                        title: 'ОЖИДАЕМ:',
                        subtitle: [
                            'Желание развиваться в направлении тестирования и погружаться в банковские процессы',
                            'Базовые технические знания',
                            'Понимание основ жизненного цикла ПО и основ тестирования',
                        ],
                    },
                ],
            },
        ],
    },
    {
        id: '7',
        name: 'Функциональная архитектура',
        list: [
            {
                title: 'Стажер в управление ИТ Архитектуры',
                info: [
                    {
                        title: 'С&nbsp;ЧЕМ БУДЕТЕ РАБОТАТЬ:',
                        subtitle: [
                            'Взаимодействовать с заказчиком, снимать устные или письменные требования к доработке',
                            'Уточнять требования, формализацию их в понятном для команд разработки виде, создавать архитектурные схемы, защищать разработанную архитектуру решения',
                            'Участвовать в оценке проекта командами разработки',
                            'Вести архитектурный надзор при разработке решения',
                        ],
                    },
                    {
                        title: 'ОЖИДАЕМ:',
                        subtitle: [
                            'Желание развиваться в направлении архитектуры ИТ и погружаться в банковские процессы',
                        ],
                    },
                ],
            },
        ],
    },
    {
        id: '8',
        name: 'Тестирование',
        list: [
            {
                title: 'Стажер в дирекцию дистанционных продаж',
                info: [
                    {
                        title: 'С&nbsp;ЧЕМ БУДЕТЕ РАБОТАТЬ:',
                        subtitle: [
                            'Тестировать роботизированный процесс',
                            'Работать со стендами роботизации',
                            'Проверять доработки роботизированных систем',
                        ],
                    },
                    {
                        title: 'ОЖИДАЕМ:',
                        subtitle: [
                            'Желание развиваться в направлении анализа данных',
                            'Уверенное знание Excel',
                            'Аналитический склад ума',
                        ],
                    },
                ],
            },
        ],
    },
];
