import clsx from 'clsx';
import React, { useEffect } from 'react';
import Collapsible from 'react-collapsible';

import AnimatedComponent from '@/components/Animation';
import HRArrow from '@/components/blocks/HRLanding/HRFaq/HRArrow';

import cn from './style.module.sass';

interface IHRFaqItem {
    title: string;
    subtitle: string;
    handler?: () => void;
}

function HRFaqItem({ title, subtitle, handler }: IHRFaqItem) {
    useEffect(() => {
        document.getElementsByClassName('openModal')[0].addEventListener('click', handler);
    }, []);

    const getTrigger = (trigger: string) => (
        <div className={clsx(cn.triggerWrap)}>
            <div className={cn.trigger} dangerouslySetInnerHTML={{ __html: trigger }} />
            <div className={cn.arrow}>
                <HRArrow />
            </div>
        </div>
    );

    return (
        <div className={cn.hrFaqItem}>
            <AnimatedComponent className={clsx(cn.hrFaqItemWrap)} classNameActive={cn.animationInit}>
                <Collapsible
                    className={cn.hrFaqItemCollapsible}
                    openedClassName={clsx(cn.hrFaqItemCollapsible, cn.open)}
                    trigger={getTrigger(title)}
                    triggerOpenedClassName={cn.open}
                    triggerTagName="div"
                    transitionTime={300}
                    easing="ease-in-out"
                >
                    <div className={cn.hrFaqItemCollapsibleSubtitle} dangerouslySetInnerHTML={{ __html: subtitle }} />
                </Collapsible>
            </AnimatedComponent>
        </div>
    );
}

export default HRFaqItem;
