export const hrConditionsList = [
    {
        title: 'ЧТО ПРЕДЛАГАЕМ ',
        subtitle: 'Стажировка по&nbsp;направлениям&nbsp;IT и&nbsp;Аналитика с&nbsp;окладом 55&nbsp;000&nbsp;₽',
    },
    {
        title: 'КОГО ЖДЕМ',
        subtitle: 'Студенты очных форм обучения от&nbsp;3&nbsp;курса бакалавриата',
    },
    {
        title: 'КАК ПОПАСТЬ',
        subtitle: 'Подать заявку и&nbsp;пройти отбор: есть всего 18&nbsp;мест!',
    },
];
