import { ISelectElement } from '@/types/select';

export const hrLandingFormOptions: ISelectElement<string>[] = [
    { value: 'Аналитик', label: 'Аналитик' },
    { value: 'CRM', label: 'CRM' },
    { value: 'Бизнес процессы', label: 'Бизнес процессы' },
    { value: 'Управление проектами', label: 'Управление проектами' },
    { value: 'QA', label: 'QA' },
    { value: 'Функциональная архитектура', label: 'Функциональная архитектура' },
    { value: 'Тестирование', label: 'Тестирование' },
    { value: 'DWH', label: 'DWH' },
];

export const levelStudyOptions: ISelectElement<string>[] = [
    { value: '1 курс', label: '1 курс' },
    { value: '2 курс', label: '2 курс' },
    { value: '3 курс', label: '3 курс' },
    { value: '4 курс', label: '4 курс' },
    { value: 'магистратура', label: 'магистратура' },
    { value: 'аспирантура', label: 'аспирантура' },
    { value: 'выпускник', label: 'выпускник' },
    { value: 'другое', label: 'другое' },
];

export const aboutOptions: ISelectElement<string>[] = [
    {
        value: 'Рекламные публикации в социальных сетях',
        label: 'Рекламные публикации в социальных сетях',
    },
    { value: 'в ВУЗе', label: 'в ВУЗе' },
    { value: 'от друзей', label: 'от друзей' },
    { value: 'через поисковик в интернете', label: 'через поисковик в интернете' },
    { value: 'другое:', label: 'другое:' },
];
